/* Default to dark mode */
:root {
  --background-color: #0c1016;
  --font-color: #ffffff;
  --header-border-color: #2E90FA;
  --input-border-color: #595959;
  --input-focus-border-color: #2E90FA;
  --card-background: #13161c;
  --gradient-highlight: rgba(46, 144, 250, 0.1);
  --card-hover-background: rgba(46, 144, 250, 0.4);


    --gray-5:#010101; /*dark*/
    --gray-10:#040404;
    --gray-15:#070808;
    --gray-20:#0D0D0E;
    --gray-25:#141516;
    --gray-30:#1E2022;
    --gray-35:#2D2F31;
    --gray-40:#404346;
    --gray-45:#5B5E62;
    --gray-50:#7C8084;
    --gray-55:#9DA1A5;
    --gray-60:#B9BDC1;
    --gray-65:#CDD1D5;
    --gray-70:#DCE0E3;
    --gray-75:#E7EAED;
    --gray-80:#EFF2F5;
    --gray-85:#F4F7FA;
    --gray-90:#F7FAFD;
    --gray-95:#FCFEFE; /*light*/
    --animate:200ms;
}

/* Light mode */
body.light {
  --background-color: #FFF;
  --font-color: #000000;
  --header-border-color: #2E90FA;
  --input-border-color: #cccccc;
  --input-focus-border-color: #2E90FA;
  --card-background: #fafafa;
  --gradient-highlight: rgba(46, 144, 250, 0.1);
  --card-hover-background: rgba(46, 144, 250, 0.4);
}

body {
  margin: 0;
  height: 100vh;
  display: grid;
  place-items: center;
  background: var(--background-color);
  color: var(--font-color);
  font-family: "Arial", serif;
}

.App-header {
  padding: 25px;
  margin: 0px 25px 25px;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid var(--header-border-color);
}

.Search-wrapper {
  padding: 0px 25px;
  margin: 0px 25px;
  display: flex;
  flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Search-wrapper > div {
  flex: 1;
}

.Search-container {
  text-align: right;
}

.SearchInput {
  background: transparent;
  padding: 5px;
  font-size: 1rem;
  width: 250px;
  height: 20px;
  outline: none;
  -webkit-appearance: none;
  border: none;
  border-bottom: 2px solid var(--input-border-color);
  color: var(--font-color);
}

.SearchInput:focus {
  border-color: var(--input-focus-border-color);
}

.cards {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  grid-template-rows: repeat(4, 200px);
  gap: 18px;
  padding: 32px;
}

.card :is(i, h2) {
  opacity: 0.45;
  transition: 0.3s;
}

.card i {
  font-size: 48px;
}
.card h2 {
  margin: 0;
  font-size: 14px;
  hyphens: auto;
  overflow: hidden;
  word-break: break-word;
  width: 100%;
  text-align: center;
}

.card:hover :is(i, h2) {
  opacity: 1;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 32px;
  box-sizing: border-box;
  background: var(--card-background);
  border-radius: inherit;
  transition: all 0.25s;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
}

.cards .card:hover::before {
  opacity: 1;
}

.cards:hover .card {
  background: radial-gradient(800px circle at var(--xPos) var(--yPos), var(--card-hover-background), transparent 15%);
}

.card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(400px circle at 0 0, rgba(0, 255, 241, 0), transparent 0%);
  border-radius: 8px;
  transition: 0.15s;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: radial-gradient(500px circle at var(--xPos) var(--yPos), var(--gradient-highlight), transparent 35%);
  opacity: 0;
  transition: all 0.15s ease-in-out;
}


/* Mobile: Nur eine Karte pro Zeile */
@media (max-width: 600px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Tablet: Zwei Karten pro Zeile */
@media (min-width: 601px) and (max-width: 900px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablet 2 : 3 Karten pro Zeile */
@media (min-width: 901px) and (max-width: 1000px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Desktop: Fünf Karten pro Zeile (wie in deinem ursprünglichen Code) */
@media (min-width: 1001px) and (max-width: 1200px)  {
  .cards {
    grid-template-columns: repeat(4, 200px);
  }
}

/* Desktop 2: Fünf Karten pro Zeile (wie in deinem ursprünglichen Code) */
@media (min-width: 1201px) {
  .cards {
    grid-template-columns: repeat(5, 200px);
  }
}


/** DARK / LIGHT MODE TOGGLE **/


.mode-toggle {
  display:inline-flex;
  flex-direction:row;
  align-items:center;
}
.label-dark {
  letter-spacing:-0.15px;
}
.label-light {
  letter-spacing:-0.031px;
}
.mode-toggle h6,
body.dark .mode-toggle h6 {
  text-transform:uppercase;
}
.label-dark,
.label-light {
  padding:8px 12px;
  min-width:32px;
  text-align:center;
  color:var(--gray-45);
}
body.dark .label-dark,
body.dark .label-light {
  color:var(--gray-60);
}
.label-dark:hover {
  color:var(--gray-40);
}
.label-dark:active {
  color:var(--gray-5);
}
body.dark .label-dark {
  pointer-events:none;
}
body.dark .label-light:hover {
  color:var(--gray-95);
}
body.dark .label-light:active {
  color:var(--gray-65);
}
.toggle-switch {
  margin:0;
  padding:0;
  width:48px;
  height:28px;
  border:none;
  outline:none;
  overflow:hidden;
  position:relative;
  border-radius:14px;
  display:inline-block;
  background:var(--gray-40);
}
body.dark .toggle-switch {
  width:48px;
}
.toggle-switch,
.toggle-switch:before,
.toggle-switch:after {
  transition:all var(--animate) ease-in-out;
}
body.dark .toggle-switch {
  background:var(--gray-70);
}
.toggle-switch:before {
  top:4px;
  left:24px;
  content:"";
  width:20px;
  height:20px;
  display:block;
  position:absolute;
  border-radius:12px;
  background:var(--gray-70);
}
body.dark .toggle-switch:before {
  left:4px;
  background:var(--gray-35);
}
.toggle-switch:after {
  content:"";
  top:14px;
  right:2px;
  width:1px;
  height:1px;
  display:block;
  position:absolute;
  border-radius:0.5px;
  background:var(--gray-40);
}
body.dark .toggle-switch:after {
  top:-2px;
  right:2px;
  width:32px;
  height:32px;
  border-radius:16px;
  background:var(--gray-70);
}
.toggle-switch:hover,
.toggle-switch:hover:after {
  background:var(--gray-35);
}
.toggle-switch:focus,
.toggle-switch:active,
.toggle-switch:active:after {
  background:var(--gray-25);
}
body.dark .toggle-switch:hover,
body.dark .toggle-switch:hover:after {
  background:var(--gray-95);
}
body.dark .toggle-switch:focus,
body.dark .toggle-switch:active,
body.dark .toggle-switch:active:after {
  background:var(--gray-65);
}
.noselect {
  cursor: pointer;
  user-select:none;
  -ms-user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
}